<template>
    <div>
        <!-- Button in the bottom-right corner -->
        <div class="d-flex justify-end mr-5">
            <v-btn
            @click="showChat = true"
            color="primary"
            icon
            large
            right
            width="60"
            height="60"
            >
            <v-icon size="50">mdi-chat</v-icon>
            </v-btn>
        </div>

        <!-- Expanding chat window -->
        <v-dialog
            min-width="300"
            max-width="1024"
            v-model="showChat"
            persistent
            transition="dialog-transition"
            hide-overlay
        >
            <v-card height="90vh" class="d-flex flex-column" style="overflow: hidden;">
                <!-- Chat window header -->
                <v-toolbar color="primary" dark style="flex: 0 0 auto;">
                    <v-toolbar-title>CUA Assistant</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showChat = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <!-- Chat body -->
                <v-list ref="chatMessages" class="chat-messages" style="overflow-y: auto; padding: 0 20px; flex: 1;">
                    <v-list-item
                        v-for="(message, index) in allMessages"
                        :key="index"
                        class="message"
                    >
                        <v-list-item-content>
                            <h4 v-if="message.type == 'user'">User:</h4>
                            <h4 v-else>CUA Assistant:</h4>
                            <v-list-item-content v-if="message.type === 'user'" style="margin-left: auto;">{{ message.text }}</v-list-item-content>
                            <v-list-item-content v-else>
                                <div v-html="compiledMarkdown(message.text)"></div>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="waitingForAssistantResponse" class="message">
                        <v-list-item-content>
                            <h4>CUA Assistant:</h4>
                            <v-list-item-content>
                                <v-skeleton-loader type="text" style="max-width: 6ch;"></v-skeleton-loader>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <hr class="mx-4 mb-2" color="lightgrey"/>

                <v-card-actions class="d-flex justify-center" style="flex: 0 0 auto;">
                    <!-- Microphone button -->
                    <v-btn 
                        icon
                        elevation="1"
                        :color="micButtonColor" 
                        ripple
                        @mousedown="startRecognition" 
                        @mouseup="stopRecognition"
                        @mouseleave="stopRecognition"
                        @touchstart.prevent="startRecognition" 
                        @touchend.prevent="stopRecognition"
                        :disabled="!enabledInput"
                        width="90"
                        height="90" 
                        :class="micButtonClass"
                    >
                        <span>
                            <v-icon size="70" :class="micButtonClass">mdi-microphone</v-icon>
                        </span>
                    </v-btn>
                </v-card-actions>
                <!-- Input bar -->
                <v-card-actions style="margin-top: bottom; flex: 0 0 auto;">
                    <!-- Text to speech button -->
                    <v-btn icon :color="textToSpeechButtonColor" @click="enabledTextToSpeech = !enabledTextToSpeech" class="mr-1">
                        <v-icon v-if="enabledTextToSpeech">mdi-volume-high</v-icon>
                        <v-icon v-else>mdi-volume-off</v-icon>
                    </v-btn>
                    <div class="input-area">
                        <v-text-field
                            v-model="prompt"
                            placeholder="Message CUA Assistant"
                            append-icon="mdi-send"
                            @click:append="submitPrompt"
                            @keydown.enter.prevent="submitPrompt"
                            :disabled="!enabledInput"
                        />
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/*
References:
    Speech to Text / Text to Speech - https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API#speech_synthesis
    Reading Stream - https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
*/
import Vue from 'vue';
import marked from 'marked';


const synth = window.speechSynthesis;


export default {
    name: 'ChatBot',
    props: {
    },
    data() {
        return {
            prompt: '',
            userMessages: [],
            assistantMessages: ["I'm here to assist you in exploring treatment options for kidney cancer, bladder cancer, mCRPC, and mCSPC. <br>How can I help you today?"],
            markdown: '',
            voice: null,
            showChat: false, // Controls visibility of chat window
            newMessage: '',
            micRecording: false,
            isSpeaking: false,
            enabledTextToSpeech: false,
            recognition: null,
            enabledInput: true,
            waitingForAssistantResponse: false,

        }
    },
    mounted() {
        this.initSpeechRecognition();
        // Wait until voices are loaded
        synth.onvoiceschanged = () => {
            const voices = synth.getVoices() 
            const selectedVoice = 'Microsoft Emma Online (Natural) - English (United States)'
            this.voice = voices.find(voice => voice.name === selectedVoice);
            if (!this.voice) {
                console.error(`Voice: ${selectedVoice} not found`);
            }
        };
    },
    methods: {
        async submitPrompt() {
            this.userMessages.push(this.prompt)
            this.prompt = '';

            try {
                // Use the Fetch API for streaming the response
                // Not using self.sendRequest (axios): https://stackoverflow.com/a/60062080
                // let self = this
                this.waitingForAssistantResponse = true;
                const response = await fetch("https://chatbot-api.cuakidneycancerdecisiontool.ca" + '/messageChatBot', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ prompt: this.userMessages[this.userMessages.length - 1] })
                });
                if (!response.ok) {
                    // Handle the error based on the status code
                    const errorText = await response.text();
                    console.error('Error:', response.status, errorText);
                    throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
                }

                // Handle streaming
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let responseText = '';
                let chunk = '';
                let utterances = '';
                this.assistantMessages.push('')

                // Read the stream chunk by chunk
                // eslint-disable-next-line
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        this.enabledInput = true;
                        break;
                    }

                    // Decode and append the received chunk to the response text
                    chunk += decoder.decode(value, { stream: true });
                    responseText += chunk
                    utterances += chunk
                    // Update assistant message with streamed response
                    
                    this.waitingForAssistantResponse = false;
                    Vue.set(this.assistantMessages, this.assistantMessages.length - 1, responseText.replace(/【.*?】/g, ''))
                    
                    // Speech as soon as a sentence is streamed.
                    if (this.enabledTextToSpeech && utterances.includes('.')) {
                        this.textToSpeech(utterances);
                        utterances = '';
                    }
                    chunk = '';
                }

                if (this.enabledTextToSpeech && utterances) {
                    this.textToSpeech(utterances);
                }
            } catch (error) {
                if (error.message.includes("504")) {
                    Vue.set(this.assistantMessages, this.assistantMessages.length - 1, "The server is taking too long to respond. Please try again later.")
                }
                else {
                    Vue.set(this.assistantMessages, this.assistantMessages.length - 1, "Sorry something went wrong.")
                }
                this.waitingForAssistantResponse = true;
                console.error('Error:', error);
            }
        },
        initSpeechRecognition() {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.lang = "en-US";
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            // Handle the result of speech recognition
            this.recognition.onresult = (event) => {
                console.log(event)
                const speech = event.results[event.results.length - 1][0].transcript;
                this.prompt += speech;
                console.log("Speech:", speech);
                
                if (!this.micRecording && this.prompt) {
                    this.submitPrompt();
                    this.prompt = "";
                    this.enabledInput = false;
                }
            };

            this.recognition.onend = () => {
                if (this.micRecording) {
                    console.log("Speech recognition service disconnected");
                    this.recognition.start();
                }
            };

            // Handle errors
            this.recognition.onerror = (event) => {
                console.error(`Speech recognition error: ${event.error}`);
            };
        },
        startRecognition() {
            if (!this.micRecording) {
                this.recognition.start();
                this.micRecording = true;
                this.micButton
                console.log("Ready to receive speech command.");
            }
        },
        stopRecognition() {
            if (this.micRecording) {
                this.recognition.stop();
                this.micRecording = false;
                console.log("Stopped listening.");
                // Handles case of user holding button longer than speaking
                setTimeout(() => {
                    if (!this.micRecording && this.prompt) {
                        this.submitPrompt();
                        this.prompt = "";
                        this.enabledInput = false;
                        console.log("FROM STOP")
                    }
                }, 500)
            }
        },
        toggleVoice() {
            if (this.micRecording) {
                this.stopRecognition();
            } else {
                this.startRecognition();
            }
        },
        textToSpeech(markdown) {
            const plainText = this.markdownToPlainText(markdown)
            const utterance = new SpeechSynthesisUtterance(plainText);
            utterance.voice = this.voice;
            utterance.volume = 0.5;
            utterance.rate = 1.25;
            utterance.pitch = 1.1;

            utterance.onstart = () => {
                this.isSpeaking = true;
            };

            utterance.onend = () => {
                if (!speechSynthesis.speaking && !speechSynthesis.pending) {
                    this.isSpeaking = false;
                }
            };
            synth.speak(utterance)
        },
        compiledMarkdown(text) {
            return marked(text);
        },
        markdownToPlainText(markdown) {
            const html = marked(markdown);
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || "";
        },
        scrollToLatestMessage() {
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatMessages.$el;
                chatContainer.scrollTop = chatContainer.scrollHeight;
            });
        },
    },
    computed: {
        api: function(){
            return this.$store.getters.api[this.$store.getters.env]
        },
        allMessages() {
            const combined = [];
            const length = Math.max(this.userMessages.length, this.assistantMessages.length);
            for (let i = 0; i < length; i++) {
                if (this.assistantMessages[i]) {
                    combined.push({ text: this.assistantMessages[i], type: 'assistant' });
                }
                if (this.userMessages[i]) {
                    combined.push({ text: this.userMessages[i], type: 'user' });
                }
            }
            return combined;
        },
        micButtonColor() {
            return this.micRecording ? 'primary' : 'none';
        },
        textToSpeechButtonColor() {
            return this.enabledTextToSpeech ? 'primary' : 'none';
        },
        micButtonClass() {
            return this.micRecording ? 'scaled-mic-button' : '';
        }
    },
    watch: {
        userMessages() {
            this.scrollToLatestMessage();
        },
        assistantMessages() {
            this.scrollToLatestMessage();
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.input-area {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 50px;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.message {
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
}

.scaled-mic-button {
    transform: scale(1.2);
    transition: transform 0.2s ease;
}

</style>